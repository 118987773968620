<template>
    <div class="container">
    <div class="d-flex justify-content-between align-items-center mb-n2">
        <h4 style="font-weight: 500">Nouvelle Facture</h4> 
        </div>
        <hr class="" />
        <div class="row">
        
            <div class="col-md-7 mx-auto">
                <div class="card ">
                    <div class="card-body card-reversed "  v-loading="(customers.length > 0 && paymentMethods.length > 0 && typeInvoices.length > 0 && accountingCategories.length > 0) ? false :true">
                        <mdb-card-title class="color-primary-reversed">Creer Une Facture</mdb-card-title>
                        <form @submit.prevent="saveInvoice">
                            <mdb-row>
                                    <mdb-col sm="12" md="12" lg="12">
                                        <fieldset>
                                            <!-- <legend>Clients</legend> -->
                                            <mdb-row>
                                                <mdb-col sm="12" md='12' >
                                                    <mdb-input  
                                                    wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"

                                                    :validation="$store.state.invoice.errors.num_facture ? true :false" :invalidFeedback="$store.state.invoice.errors.num_facture"
                                                    v-model="form.num_facture" :disabled="$store.state.auth.company.setting.is_automatic === 1 ? true: false" outline label="N° Facture" ></mdb-input>
                                                </mdb-col>
                                                <mdb-col sm="12" md='12' lg="6" >
                                                    <mdb-select flipOnScroll wrapperClass=""   labelClass=" color-normal-reversed" caretClass="color-primary-reversed " 
                                                        :disabled="(form.num_facture_initiale)?true:false"
                                                        search v-model="customerOption" @getValue="getCustomer" outline label="Client" ></mdb-select>
                                                    <div  style="color:#dc3545;font-size:.8rem" v-if="$store.state.invoice.errors.customer_id" >{{$store.state.invoice.errors.customer_id}}</div>

                                                </mdb-col>
                                                <mdb-col sm="12" md='12'  lg="6">
                                                    <mdb-input  
                                                    wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                                                     v-model="form.client_specification" :disabled="customerIsNotDivers" outline label="Client Spécification" ></mdb-input>
                                                </mdb-col>
                                                <mdb-col sm="12" md='12' lg="12" >
                                                    <mdb-select flipOnScroll wrapperClass=""   labelClass=" color-normal-reversed" caretClass="color-primary-reversed " 
                                                    search v-model="paymentOption" @getValue="getPayment" outline label="Methode de paiement" ></mdb-select>
                                                    <div  style="color:#dc3545;font-size:.8rem" v-if="$store.state.invoice.errors.payment_method_id" >{{$store.state.invoice.errors.payment_method_id}}</div>

                                                </mdb-col>
                                                
                                            </mdb-row>
                                        </fieldset>
                                    </mdb-col>
                                    <mdb-col sm="12" md="12" lg="12" >
                                        <fieldset>
                                            <legend class="color-primary-reversed">Références</legend>
                                            <mdb-row>
                                                <mdb-col sm="12" md='6' >
                                                    <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                                                      outline  v-model="form.ref_bl" label="Référence BL" ></mdb-input>
                                                </mdb-col>
                                                <mdb-col sm="12" md='6' >
                                                    <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                                                      outline  v-model="form.ref_bc" label="Référence BC" ></mdb-input>
                                                </mdb-col>
                                            </mdb-row>
                                        </fieldset>
                                    </mdb-col>

                                    <mdb-col sm="12" md="12">
                                        <fieldset>
                                            <div class="d-flex justify-content-between">
                                                <legend class="color-primary-reversed">Articles</legend>
                                                <mdb-btn size="sm" icon="plus" color="primary" @click="addOrEdit(null)" ></mdb-btn >
                                            </div>
                                            <div class="invoice-list-mobile">
                                                <mdb-list-group class="mt-3">
                                                    <mdb-list-group-item class="px-2" v-for="article in form.invoice_details" :key="article.ido">
                                                    <div>
                                                        <div style="font-size:0.9rem">{{article.designation}} </div>
                                                        <div class="d-flex justify-content-arround">
                                                            <div class="pr-1" style="font-size:12px; color:#555">QTE: {{article.quantity  | roundNumber | formatNumber }}</div>
                                                            <div class="pr-1" style="font-size:12px; color:#555">PUHT: {{article.puht  | roundNumber | formatNumber }}</div>
                                                            <div class="pr-1" style="font-size:12px; color:#555">PUTTC: {{article.puttc  | roundNumber | formatNumber }}</div>
                                                            <div class="pr-1" style="font-size:12px; color:#555">MTTC: {{article.mttc  | roundNumber | formatNumber }}</div>
                                                        </div>
                                                        
                                                    </div>        
                                                    
                                                     <button  type="button" @click="removeArticle(article)" style="border-radius:50%;border:none;padding:2px 8px; background:#ef5350;" class="z-depth-1"><mdb-icon style="cursor:pointer;" class="z-depth-1" size="sm" color="white" icon="trash" ></mdb-icon></button>
                                                    </mdb-list-group-item>
                                                </mdb-list-group>
                                            </div>
                                            <mdb-tbl class="invoice-list-desktop " responsiveMd>
                                                <mdb-tbl-head class="color-normal-reversed">
                                                    <tr>
                                                        <th>ITEM</th>
                                                        <th style="text-align:right" >QTE</th>
                                                        <th style="text-align:right" >PUHT</th>
                                                        <th style="text-align:right" >PUTTC</th>
                                                        <th style="text-align:right" >MTTC</th>
                                                        <th style="text-align:right" ></th>
                                                    </tr>
                                                </mdb-tbl-head>
                                                <mdb-tbl-body class="color-normal-reversed">
                                                <tr v-for="article in form.invoice_details" :key="article.ido" >
                                                    <th>{{article.designation}}</th>
                                                    <td style="text-align:right" >{{article.quantity  | roundNumber | formatNumber }}</td>
                                                    <td style="text-align:right" >{{article.puht  | roundNumber | formatNumber }}</td>
                                                    <td style="text-align:right" >{{article.puttc  | roundNumber | formatNumber }}</td>
                                                    <td style="text-align:right" >{{article.mttc  | roundNumber | formatNumber }}</td>
                                                    <td class="d-flex justify-content-end">
                                                        <a @click="removeArticle(article)" class="text-danger"> Supprimer</a>
                                                    </td>
                                                </tr>
                                                </mdb-tbl-body>
                                            </mdb-tbl>
                                        </fieldset>
                                    </mdb-col>

                                    <mdb-col sm="12" md="12" style="margin-top:50px" class="d-flex justify-content-end flex-wrap">
                                        <mdb-btn color="grey"   size="md" @click="goto('/factures')">Annuler</mdb-btn>
                                        <mdb-btn size="md" color="primary" type="submit"  :disabled="submitBtn" >
                                            <span v-if="!submitBtn">Sauvegarder</span>
                                            <span v-if="submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span v-if="submitBtn" class="pl-2">Loading...</span>
                                        </mdb-btn>
                                    </mdb-col>
                            </mdb-row>
                        </form>
                    </div>
                </div>
            </div>
        </div>
         
        <mdb-modal
            centered
            size="md"
            direction="top"
            position="top"
            :show="modalArticle.show"
        > 
            <form @submit.prevent="addArticle"> 
                <mdb-modal-header>
                    <mdb-modal-title class="">
                        {{ modalArticle.title }}
                    </mdb-modal-title>
                </mdb-modal-header>
                <mdb-modal-body  class="modal-tec card-reversed px-3">
                    <mdb-row>
                        <mdb-col sm="12" md="12">
                            <mdb-select flipOnScroll wrapperClass=""   labelClass=" color-normal-reversed" caretClass="color-primary-reversed " size="lg" v-model="articleOption" @getValue="getArticle" search outline label="Article"></mdb-select>
                        </mdb-col>
                        <mdb-col sm="12" md="12">
                            <mdb-form-inline>
                                <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                                  @change.native="typeCalculChange" type="radio"  id="option5-2" name="quantitystroe" radioValue="amount" v-model="articleForm.typeCalcul" label="Montant" />
                                <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                                  @change.native="typeCalculChange" type="radio" id="option5-1" name="quantitystroe" radioValue="quantity" v-model="articleForm.typeCalcul" label="Quantité" />
                            </mdb-form-inline>
                        </mdb-col>
                        <mdb-col sm="12" md="4" >
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                              outline v-if="articleForm.typeCalcul === 'quantity'" v-model="articleForm.quantity" v-on:change="quantityChange" type="number" :min="0"  :step="0.00000000000000000001" label="Quantité"></mdb-input>
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                               outline v-if="articleForm.typeCalcul === 'amount'" v-model="articleForm.amount" v-on:change="amountChange" type="number" :min="0"  :step="0.00000000000000000001" label="Montant Vente"></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="4">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                              outline v-model="articleForm.reduction" :disabled="!articleForm.article_specific" v-on:change="reductionChange" type="number" :min="0"  :step="0.00000000000000000001" label="Réduction"></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="4">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                              outline  v-model="articleForm.puht" @change="puhtChange" :disabled="!isNotAccountDetails && articleForm.article_specific" type="number" :min="0"  :step="0.00000000000000000001" label="Prix unitaire"></mdb-input>
                        </mdb-col>
                        
                        <mdb-col sm="12" md="6">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                              outline v-model="articleForm.puttc" type="number" :min="0"  :step="0.00000000000000000001" label="Prix TTC" disabled></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="6">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                              outline v-model="getMTTC" type="number" :min="0"  :step="0.00000000000000000001" label="Montant TTC " disabled></mdb-input>
                        </mdb-col>
                    </mdb-row>
                    <p>La réduction s'applique sur le Prix Unitaire TTC</p>
                </mdb-modal-body>
                <mdb-modal-footer class="card-reversed color-normal-reversed">
                    <div class="d-flex justify-content-end">
                        <mdb-btn size="md" color="grey" @click="modalArticle.show = false">Fermer</mdb-btn>
                        <mdb-btn size="md" color="success"  :disabled="modalArticle.btn" type="submit">Sauvegarder</mdb-btn>
                    </div>
                </mdb-modal-footer>
            </form>

        </mdb-modal>
    </div>
</template>

<script>
import {
    mdbListGroup, mdbListGroupItem,
    mdbRow,mdbCol,
    mdbBtn,
    mdbSelect,mdbInput,
    mdbTbl,mdbTblHead,mdbTblBody,
    mdbModal,mdbModalTitle,mdbModalBody,mdbModalFooter,mdbModalHeader,
    mdbCardTitle,mdbIcon,
    mdbFormInline
} from 'mdbvue';
import Invoice from '../../../services/Invoice'
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - CREER FACTURE',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbListGroup, mdbListGroupItem,
        mdbBtn,
        mdbRow,mdbCol,
        mdbSelect,mdbInput,
        mdbTbl,mdbTblHead,mdbTblBody,
        mdbModal,mdbModalTitle,mdbModalBody,mdbModalFooter,mdbModalHeader,
        mdbCardTitle,mdbIcon,
        mdbFormInline
    },
    data(){
        return {
            invoice:{
                type:"Choisir un type de facture"
            },

            tva:0,
            submitBtn:false,

            form:{
               id:"",
                num_facture:"",
                num_facture_initiale:"",
                date_facture:this.$moment().format("YYYY-MM-DD"),
                client_specification:"",
                type:"",
                depot_id:"",
                ref_bl:"",
                ref_bc:"",
                ref_bh:"",
                customer_id:"",
                accounting_category_id:"",
                payment_method_id:"",
                invoice_details:[]
            },

            articleForm:{
                price:{},
                article:"",
                designation:"",
                article_exonere:false,
                article_specific:false,
                typeCalcul: "amount",
                amount: 0,
                puht:0,
                puttc:0,
                quantity:0,
                mttc:0,
                reduction:0
            },
            customerIsNotDivers:true,
            isNotAccountDetails:false,
            invoiceInitials:[],
            invoiceInitialOption:[],
            articles:[],
            articleOption:[],
            paymentOption:[],
            paymentMethods:[],
            depots:[],
            depotOption:[],
            accountingCategories:[],
            accountOption:[],
            customers:[],
            customerOption:[],
            typeInvoiceOption:[],
            typeInvoices:[],
            accountDisabled:false,
            numFactureInitBool:false,

            modalArticle:{
                show: false,
                edit: false,
                title: "Ajouter un article",
                content: {},
                btn:false
            },

            modalPreview:{
                show: false,
                edit: false,
                title: "Aperçu",
                content: {},
                btn:false
            }
        }
    },
    methods:{
        //Fonction de fonctionnement

        async saveInvoice(){
            this.$store.commit('invoice/SET_CLEAN')
            this.submitBtn = !this.submitBtn
            await this.form.invoice_details.reverse()
            this.form.code_num_facture = this.$store.state.auth.company.id+'_'+this.form.num_facture
            await this.$store.dispatch('invoice/save',this.form)
            .then((response) => {
                this.submitBtn = !this.submitBtn
                this.$router.push({
                    path:"/factures/details/"+response.data.invoice.id,
                 })
                this.$notify({
                     
                    message: response.data.message,
                    type: 'success'
                })
            })
            .catch((error) => {
                this.submitBtn = !this.submitBtn
                if (error.response.data.errors) {
                    this.$store.commit('invoice/SET_NF', error.response.data.errors.num_facture)
                    this.$store.commit('invoice/SET_DF', error.response.data.errors.date_facture)
                    this.$store.commit('invoice/SET_TYPE', error.response.data.errors.type)
                    this.$store.commit('invoice/SET_DI', error.response.data.errors.depot_id)
                    this.$store.commit('invoice/SET_CI', error.response.data.errors.customer_id)
                    this.$store.commit('invoice/SET_ACI', error.response.data.errors.accounting_category_id)
                    this.$store.commit('invoice/SET_PMI', error.response.data.errors.payment_method_id)
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        calulRapide(){
            this.articleForm.quantity =parseFloat( this.articleForm.amount) / (parseFloat(this.articleForm.price.ttc) - parseFloat(this.articleForm.reduction))
        },

        async loadDepots () {
			this.$nprogress.start()
            await this.$store.dispatch('depot/findAll')
            .then(response => {
				this.$nprogress.done()
                this.depots = response.data.depots.filter(depot => depot.is_active === 1)

                this.depots.forEach(depot => {
                    this.depotOption.push({
                        text:depot.designation,
                        value:depot.id
                    })
                });
            }).catch((error) => {
				this.$nprogress.done()
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        clearArticleForm(){
            this.articleForm.article_specific = false,
            this.articleForm.article_exonere = false,
            this.articleForm.typeCalcul = "amount",
            this.articleForm.puht = 0,
            this.articleForm.puttc = 0,
            this.articleForm.quantity = 0,
            this.articleForm.amount = 0,
            this.articleForm.mttc = 0,
            this.articleForm.reduction = 0
        },

        getDepot(value){
            this.form.depot_id = value
        },

        getCustomer(value,text){
            this.form.customer_id = value

            if(text.toLowerCase() === 'divers'){
                this.customerIsNotDivers = false;
            }else{
                this.customerIsNotDivers = true
            }
        },

        getArticle(value,text){
            this.clearArticleForm()
            this.articleForm.article = value
            this.articleForm.designation = text.split(' - ')[1]

            this.getPrice()
        },

        getInitialInvoice(value){
            this.form.num_facture_initiale = value

            if(value){
                let inv_customer = 0;
                this.invoiceInitials.forEach(inv => {
                    if (inv.num_facture === value) {
                        inv_customer = inv.customer_id
                        this.form.customer_id = inv_customer
                    }
                });

                this.customerOption = []

                this.customers.forEach(customer => {
                    this.customerOption.push({
                        text:customer.designation,
                        value:customer.id,
                        selected: (inv_customer === customer.id ) ? true: false
                    })
                });
            }
        },

         getPrice(){
            this.articles.forEach(article => {
                if (article.id === this.articleForm.article) {
                    
                    if(article.exonere === 0){
                        this.articleForm.article_exonere = false;
                    }
                    else{
                        this.articleForm.article_exonere = true;
                    }

                    if (article.specific === 1) {
                        this.articleForm.article_specific = true;

                        this.articleForm.typeCalcul = 'amount';
                        this.articleForm.quantity = 0
                        

                        let $date = this.form.date_facture.split('-');
                        let priceMonth = $date[0]+'/'+$date[1];
                        let code = this.$store.state.auth.company.id+"/"+priceMonth+"/"+this.articleForm.article
                        this.$store.dispatch('price/findByCodePrice',{
                            code:code,
                            article_id:this.articleForm.article})
                        .then((response) => {
                            if (response.data.price === null) {
                               this.$notify({
                                       
                                    message: "Le prix de l'article n'est pas/plus référencé dans la structure de prix. Contactez l'administrateur.",
                                    type: 'danger'
                                }) 
                                return ;
                            }
                            this.articleForm.price = response.data.price;

                            this.calculPUHT()
                            this.calculPUTTC()
                            this.calculMTTC()
                        })
                        .catch((error) =>{
                            this.$notify({
                                   
                                // message: "Le prix de l'article n'est pas/plus référencé dans la structure de prix. Contactez l'administrateur.",
                                message: error.response.data.message  || error.response.data.error,
                                type: 'danger'
                            })
                        })
                    }else{
                        this.articleForm.article_specific = false;

                        this.articleForm.typeCalcul = 'quantity';
                        this.articleForm.amount = 0

                        this.articleForm.price = {};
                        this.articleForm.puht =  article.puht

                        this.calculPUHT()
                        this.calculPUTTC()
                        this.calculMTTC()
                    }
                }
            });

            
        },

        async calculPUHT(){
            this.articleForm.puht = Invoice.calculPUHT({
                price:this.articleForm.price,
                reduction:this.articleForm.reduction,
                tva:this.tva,
                is_exonere:this.articleForm.article_exonere,
                is_specific:this.articleForm.article_specific,
                puht:this.articleForm.puht
            });
        },

        async calculPUTTC(){
            this.articleForm.puttc = Invoice.calculPUTTC({
                price:this.articleForm.price,
                reduction:this.articleForm.reduction,
                tva:this.tva,
                is_exonere:this.articleForm.article_exonere,
                is_specific:this.articleForm.article_specific,
                puht:this.articleForm.puht,
                is_not_details: this.isNotAccountDetails
            })
        },

        async calculMTTC(){
            this.articleForm.mttc = Invoice.calculMTTC({
                puttc:this.articleForm.puttc,
                qte:this.articleForm.quantity,
            })
        },

        reductionChange(){
            
            if(this.articleForm.typeCalcul === 'amount') {
                this.amountChange()
            }

            this.calculPUHT()
            this.calculPUTTC()
            this.calculMTTC()
        },

        puhtChange(){
            this.calculPUTTC()
            this.calculMTTC()
        },

        quantityChange(){
            this.calculMTTC()
        },

        amountChange(){
            if (this.articleForm.article_specific) {
            this.articleForm.quantity =
                parseFloat(this.articleForm.amount) /
                (parseFloat(this.articleForm.price.ttc) - parseFloat(this.articleForm.reduction));
            }else{
            this.articleForm.quantity =
                parseFloat(this.articleForm.amount) /
                (parseFloat(this.articleForm.puttc) - parseFloat(this.articleForm.reduction));
            }
            this.calculMTTC()
        },

        typeCalculChange(){
            if(this.articleForm.typeCalcul === 'quantity') {
                this.articleForm.amount = 0;
            }else{
                this.articleForm.quantity = 0;
            }
        },

        getAccount(value, text){
            this.form.accounting_category_id = value
            this.isNotAccountDetails = (text.toLowerCase() === 'details') ? false : true;
        },

        getPayment(value){
            this.form.payment_method_id = value
        },

        async loadCustomers () {
            this.customerOption = []
			this.$nprogress.start()
            await this.$store.dispatch('customer/findAll')
            .then(response => {
				this.$nprogress.done()
                this.customers = response.data.customers.filter(customer => customer.is_active === 1)
                this.customers.forEach(customer => {
                    this.customerOption.push({
                        text:customer.designation,
                        value:customer.id
                    })
                });
            }).catch((error) => {
				this.$nprogress.done()
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async loadPayments () {
            this.paymentOption = []
			this.$nprogress.start()
            await this.$store.dispatch('payment/findAll')
            .then(response => {
				this.$nprogress.done()
                this.paymentMethods = response.data.paymentMethods.filter(payment => payment.is_active === 1)
                this.paymentMethods.forEach(p => {
                    this.paymentOption.push({
                        text:p.designation,
                        value:p.id
                    })
                });
            }).catch((error) => {
				this.$nprogress.done()
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async loadTypeInvoice () {
            this.typeInvoiceOption = []
			this.$nprogress.start()
            await this.$store.dispatch('type_invoice/findAll')
            .then(response => {
				this.$nprogress.done()
                this.typeInvoices = response.data.typeInvoices.filter(type => type.is_active === 1 && type.ref !== 'FA' && type.ref !== 'EA')

                this.typeInvoices.forEach(p => {
                    this.typeInvoiceOption.push({
                        text:p.type,
                        value:p.ref,
                        selected:(p.ref.toLowerCase() === 'fv') ? true :false
                    })
                    if(p.ref.toLowerCase() === 'fv'){
                        this.form.type = p.ref 
                    }
                });
            }).catch((error) => {
				this.$nprogress.done()
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async loadAccountingCategories () {
            this.accountOption = []
			this.$nprogress.start()
            await this.$store.dispatch('account_c/findAll')
            .then(response => {
				this.$nprogress.done()
                this.accountingCategories = response.data.accountingCategories.filter(category => category.is_active === 1)

                 this.accountingCategories.forEach(account => {
                    if(account.category.toLowerCase() === 'details'){
                        this.form.accounting_category_id = account.id
                    }
                    this.accountOption.push({
                        text:account.category,
                        value:account.id,
                        disabled:(account.category.toLowerCase() === 'exportation') ? true :false,
                        selected:(account.category.toLowerCase() === 'details') ? true :false
                    })
                });
            }).catch((error) => {
				this.$nprogress.done()
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async loadArticles () {
            this.articleOption = []
			this.$nprogress.start()
            await this.$store.dispatch('article/findAll')
            .then(response => {
				this.$nprogress.done()
                this.articles = response.data.articles.filter(article => article.is_active === 1)
                this.articles.forEach(article => {
                    this.articleOption.push({
                        text:article.ref+' - '+ article.designation,
                        value:article.id
                    })
                });
            }).catch((error) => {
				this.$nprogress.done()
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        addOrEdit(data = null,modal= 'article') {
            switch (modal) {
                case 'article':

                    if(!this.form.accounting_category_id){
                        this.$notify({
                            message: "Selectionnez une catégorie comptable pour continuer",
                            type: 'warning'
                        })
                        return
                    }

                    if (data instanceof Object) {
                        this.modalArticle.title = "Modifier un article";
                        this.modalArticle.content = data;
                        this.modalArticle.edit = true;
                        this.formRest(data);
                    } else {
                        this.modalArticle.title = "Ajouter un article";
                        this.modalArticle.content = {};
                        this.modalArticle.edit = false;
                        this.cleanForm();

                        if(this.articleForm.article){
                            this.getPrice()
                        }
                    }
                    this.modalArticle.show = true;
                    break;
            
                default:
                    break;
            }
        },

        dateChange(){
            if(this.form.invoice_details.length > 0){
                this.$notify({
                    message: "Le changement de date de la facture reinitialise la liste des articles.",
                    type: 'info'
                })
            }
            
           this.form.invoice_details = []
        },

        cleanForm() {
        },

        formRest() {
        },

        getInvoiceType(value, text){
            this.accountDisabled = false;
            this.numFactureInitBool = false

            this.form.type = value;
            this.invoice.type = text

            if (value.toLowerCase() === 'ev' || value.toLowerCase() === 'ea') {
                this.accountDisabled = true
                this.accountOption = []      
                this.accountingCategories.forEach(account => {
                    if(account.category.toLowerCase() === "exportation"){
                        this.form.accounting_category_id = account.id
                    }
                    this.accountOption.push({
                        text:account.category,
                        value:account.id,
                        selected:(account.category.toLowerCase() === "exportation") ?true:false
                    })
                });
 
            }else if(value.toLowerCase() === 'fv' || value.toLowerCase() === 'fa'){
                this.accountDisabled = false
                this.accountOption = []      

                this.accountingCategories.forEach(account => {
                    this.form.accounting_category_id = ""
                    this.accountOption.push({
                        text:account.category,
                        value:account.id,
                        selected:false,
                        disabled:(account.category.toLowerCase() === "exportation") ? true :false
                    })
                });
            }

            if(value.toLowerCase() === 'fa' || value.toLowerCase() === 'ea'){
                this.numFactureInitBool = false

                this.form.num_facture_initiale = ''
                this.invoiceInitialOption =[]

                this.invoiceInitialOption.push({
                        text:'Facture Initiale',
                        value:'',
                })

                this.invoiceInitials.forEach(inv => {
                    if(value.toLowerCase() === 'fa'){
                        if (inv.type.toLowerCase() === 'fv') {
                           this.invoiceInitialOption.push({
                                text:inv.num_facture,
                                value:inv.num_facture,
                            }) 
                        }
                    }

                    if(value.toLowerCase() === 'ea'){
                        if (inv.type.toLowerCase() === 'ev') {
                           this.invoiceInitialOption.push({
                                text:inv.num_facture,
                                value:inv.num_facture,
                            }) 
                        }
                    }
                });

            }else{
                this.numFactureInitBool = true
                this.form.num_facture_initiale = ''
                this.invoiceInitialOption =[]

                this.invoiceInitialOption.push({
                        text:'Facture Initiale',
                        value:'',
                })
                this.invoiceInitials.forEach(inv => {
                    this.invoiceInitialOption.push({
                        text:inv.num_facture,
                        value:inv.num_facture,
                    })
                });
            }
        },
        
        async getInvoiceNormalise(){
            await this.$store.dispatch('invoice/getInvoiceNormalise')
            .then(response => {
                this.invoiceInitials = response.data.invoices

                this.invoiceInitialOption = []
                this.invoiceInitialOption.push({
                        text:"Facture Initiale",
                        value:""
                    })
                this.invoiceInitials.forEach(inv => {
                    this.invoiceInitialOption.push({
                        text:inv.num_facture,
                        value:inv.num_facture
                    })
                });
            }).catch((error) => {
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            }) 
        },

        async addArticle() {
            if (this.form.date_facture.length === 0) { 
                this.$notify({
                       
                    message: "Veuillez renseigner la date",
                    type: 'danger'
                })
                return
            }
 
            let doublon = false;
            this.form.invoice_details.forEach((el) => {
                if (el.article === this.articleForm.article) {
                    doublon = true;
                }
            });

            if (doublon) { 
                this.$notify({
                       
                    message: "Ce produit existe déjà",
                    type: 'danger'
                })
                return;
            }

            if (this.articleForm.quantity === 0 || !this.articleForm.puht ) { 
                this.$notify({
                    message: "Renseiller les informations du produit",
                    type: 'warning'
                })
                return;
            }

            if (!parseFloat(this.articleForm.quantity )) { 
                this.$notify({
                    message: "Renseiller les informations du produit",
                    type: 'warning'
                })
                return;
            }
           
            
            let obj = {
                ido: this.articleForm.article + "" + this.articleForm.quantity.length,
                article: this.articleForm.article,
                designation: this.articleForm.designation,
                puht: this.articleForm.puht,
                puttc: this.articleForm.puttc,
                mttc: this.articleForm.mttc,
                reduction: this.articleForm.reduction,
                quantity:this.articleForm.quantity
            };
            this.form.invoice_details.unshift(obj);
            this.modalArticle.show = false;
             

            this.clearArticleForm()
        },

        async removeArticle(pos) {
            let index = this.form.invoice_details.indexOf(pos);
            this.form.invoice_details.splice(index, 1);
        },

        goto(value){
            this.$router.replace(value)
        }
    }, 

    created() {
        this.$store.commit('invoice/SET_CLEAN')

        this.loadTypeInvoice();
        this.loadDepots();
        this.loadAccountingCategories();
        this.loadCustomers();
        this.loadArticles();
        this.loadPayments();
        this.getInvoiceNormalise();

        this.$store.dispatch('price/getTVA')
        .then((response) =>{
            this.tva = response.data.tva
        })
        .catch(() =>{
            this.$notify({
                   
                message: 'impossible de récuperer la TVA',
                type: 'danger'
            })
        })
    },

    computed:{
        getMTTC(){
            return this.articleForm.mttc
        }
    }

}
</script>

<style lang="scss" scoped >
    .invoice-list-desktop{
        display:block !important;
    }

    .invoice-list-mobile{
        display:none !important;
    }
    @media (max-width: 768px) { 
        .invoice-list-desktop{
            display:none !important;
        }

        .invoice-list-mobile{
            display:block !important;
        }
    }
</style>